<template>
    <div class="admin-invite-page">
        <router-link to="/leads" class="btn btn-light mb-3">
            <span class="fas fa-chevron-left mr-1"></span>
            Back
        </router-link>
        <div class="row">
            <div class="col-md-8">
                <ui-card :title="`${$route.query.leadId ? 'Update lead':'Add new lead'}`" description="Enter the information requested below.">
                    <form id="form-cards-add" @submit.prevent="registerLead">
                        <leads-form ref="leadForm" />
                        <div class="text-end">
                            <ui-button type="submit" variant="primary" :loading="sendingInvitations">
                                {{$route.query.leadId ? 'Save':'Register'}}
                            </ui-button>
                        </div>
                    </form>
                </ui-card>
            </div>
        </div>
    </div>
</template>

<script>
import LeadsForm from '@/components/leads/Form';
import UiButton from '@/components/ui/buttons/Button';
import UiCard from '@/components/ui/Card';

export default {
    components: {
        LeadsForm,
        UiButton,
        UiCard,
    },
    data() {
        return {
            sendingInvitations: false,
        };
    },
    mounted() {
        if(this.$route.query.leadId) {
            this.getDataLead(this.$route.query.leadId);
        }        
    },
    methods: {
        async registerLead() {
            const leadForm = this.$refs.leadForm;

            try {
                if (!await leadForm.validate()) {
                    return;
                }

                this.sendingInvitations = true;

                const payload = leadForm.formData;
                   
                if(this.$route.query.leadId) {
                    await this.$axios.put(`/_/leads/${this.$route.query.leadId}`, payload);
                } else {
                    await this.$axios.post('/_/leads', payload);
                }

                this.$toast.success('saved succesfully.');
                this.$router.push('/leads');
            } catch (error) {
                const status = error?.response?.status;
                const details = error?.response?.data?.details;

                if (status === 400 && details?.length) {
                    leadForm.setBackendErrors(details);
                    return;
                }

                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.sendingInvitations = false;
            }
        },
        async getDataLead(id) {
            const { data } = await this.$axios.get(`/_/leads/${ id }`);
            const leadForm = this.$refs.leadForm;
            leadForm.formData = {
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone,
                email: data.email,
                webpage: data.webpage,
                monthly_transactions: data.monthly_transactions,
                average_ticket: data.average_ticket,
            }
        }
    }
};
</script>
