<template>
    <div class="row">
        <div class="col-md-6 mb-4">
            <ui-input
                id="firstName"
                name="firstName"
                label="First name *"
                type="text"
                v-model:value="formData.first_name"
                :field="v$.formData.first_name"
            />
        </div>
        <div class="col-md-6 mb-4">
            <ui-input
                id="lastName"
                name="lastName"
                label="Last name"
                type="text"
                v-model:value="formData.last_name"
                :field="v$.formData.last_name"
            />
        </div>
        <div class="col-md-6 mb-4">
            <ui-input
            id="email"
            name="email"
            label="Email"
            type="email"
            v-model:value="formData.email"
            :field="v$.formData.email"
            />
        </div>
        <div class="col-md-6 mb-4">
            <ui-phone-input
                id="phone"
                name="phone"
                label="Phone"
                type="phone"
                placeholder="Phone number"
                v-model:value="formData.phone"
                :field="v$.formData.phone"
            />
        </div>
        <div class="col-md-6 mb-4">
            <ui-input
                id="webpage"
                name="webpage"
                label="Web page"
                type="text"
                placeholder="https://google.com"
                v-model:value="formData.webpage"
                :field="v$.formData.webpage"
            />
        </div>
        <div class="col-md-6 mb-4">
            <ui-input
                id="monthly_transactions"
                name="monthly_transactions"
                label="Monthly transactions"
                type="number"
                v-model:value="formData.monthly_transactions"
                :field="v$.formData.monthly_transactions"
            />
        </div>
        <div class="col-md-6 mb-4">
            <ui-input
                id="average_ticket"
                name="average_ticket"
                label="Average ticket"
                type="number"
                v-model:value="formData.average_ticket"
                :field="v$.formData.average_ticket"
            />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required, helpers } from '@vuelidate/validators';

import UiInput from '@/components/ui/Input';
import UiPhoneInput from '@/components/ui/inputs/PhoneInput';

export default {
    name: 'LeadForm',
    components: { UiInput, UiPhoneInput },
    setup: () => {
        const externalResults = ref({});
        return {
            externalResults,
            v$: useVuelidate({ $externalResults: externalResults }),
        };
    },
    data() {
        return {
            formData: {
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                webpage: '',
                monthly_transactions: '',
                average_ticket: '',
            },
        };
    },
    validations() {
        return {
            formData: {
                first_name: {
                    required: helpers.withMessage('The field first name is required.', required),
                    $autoDirty: true,
                },
                last_name: {
                    required: helpers.withMessage('The field last name is required.', required),
                    $autoDirty: true,
                },
                phone: {
                    required: helpers.withMessage('The field phone is required.', required),
                    $autoDirty: true,
                },
                email: {
                    required: helpers.withMessage('The field email is required.', required),
                    email,
                    $autoDirty: true,
                },
                webpage: {
                    required: helpers.withMessage('The field webpage is required.', required),
                    $autoDirty: true,
                },
                monthly_transactions: {
                    required: helpers.withMessage('The field monthly transactions is required.', required),
                    $autoDirty: true,
                },
                average_ticket: {
                    required: helpers.withMessage('The field averga ticket is required.', required),
                    $autoDirty: true,
                },
            }
        };
    },
    methods: {
        async validate() {
           return await this.v$.$validate();
        },
        setBackendErrors(errors) {
            const formattedErrors = {};

            errors.forEach(error => {
                formattedErrors[error.field] = [error.message];
            });

            Object.assign(this.externalResults, {
                formData: formattedErrors,
            });
        },
    },
}
</script>
